#utHeader ul {
  margin: 0;
}

#utFooter a:hover {
  border-bottom: 1px solid #00c46e;
}

.rtgErrorMessageCard {
  width: 100%;
}

.rtgErrorMessageCardXs {
  margin: 0px 15px;
}