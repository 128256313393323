.noActiveBorder li button {
  border: none;
}

.chartContainer {
  width: 100%;
  height: 408px;
  margin: "0";
}

.chartContainer > canvas {
  width: 100%;
}